<template>
  <div
    class="ProgressInstance">
    <div
      class="h4">
      Your account is being created.
    </div>

    <p
      class="mt-1">
      Please wait. This shouldn't take much longer.
    </p>

    <ProgressBar
      class="__ProgressBar"
      :progress="instanceProgress"
    />
  </div>
</template>


<script>
import ProgressBar from '@/components/ProgressBar.vue';

export default {
  name: 'ProgressInstance',

  components: {
    ProgressBar,
  },

  props: {
    instanceProgress: {
      type: Number,
      required: true,
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.ProgressInstance {
  margin-top: 80px;
  text-align: center;
}

.__ProgressBar {
  max-width: 350px;
  margin: 0 auto;
  margin-top: 32px;
}
</style>
